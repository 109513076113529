import { createSlice } from '@reduxjs/toolkit';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import { apiSlice } from '../apiSlice';
import { history } from '../../routers/AppRouter';
import axios from '../../utils/axios';



const initialState = {
  currentUser: {
    permissions: [],
  },
  isLoggedIn: false,
};

// Separate auth reducer
const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (auth, action) => {
      ls('user', action.payload);
      auth.currentUser = action.payload;
      auth.isLoggedIn = true;
    },
    logout: () => { }, // triggers appReducer conditional, resets all state to initialState

    updateCurrentUser: (auth, action) => {
      const payload = {
        ...auth.currentUser,
        ...action.payload,
      };
      ls('user', payload);
      auth.currentUser = payload;
    },
  },
});

export const {
  login,
  logout,

  updateCurrentUser,
} = slice.actions;

export default slice;



// Logout with cleanup
export const startLogout = () => async (dispatch) => {
  ls.remove('user');
  dispatch(apiSlice.util.resetApiState()); // Clear RTK query cache
  dispatch(logout()); // triggers appReducer conditional, resets all state to initialState
};



const tags = {};

const authApi = apiSlice
  .enhanceEndpoints({ addTagTypes: Object.values(tags) })
  .injectEndpoints({
    endpoints: (build) => ({

      // Login
      login: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/login`,
              method: 'POST',
              data: payload,
            });
            const { cs, refresh_tos, pw_rotation, pw_token } = response.data;

            if (pw_rotation) {
              history.push(`/reset-password/${pw_token}`, { required: true });
              return { data: response.data };
            }

            ls.remove('email');
            api.dispatch(login({ ...cs, refresh_tos })); // Add user data to redux store
            history.push('/dashboard');
            return { data: response.data };
          } catch (error) {
            if (error.response?.status === 409) {
              ls('email', payload.email);
              history.push('/validate/session');
            } else {
              toast.error('It looks like something went wrong.');
            }
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Logout
      logout: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/logout`,
              method: 'GET',
            });
            api.dispatch(startLogout());
            history.push('/');
            return { data: response.data };
          } catch (error) {
            api.dispatch(startLogout());
            history.push('/');
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Get org registration data
      getRegistrationData: build.query({
        queryFn: async (payload, { forced }) => {
          try {
            const response = await axios({
              url: `${baseURL}/register/${payload.token_uuid}`,
              method: 'GET',
              forced,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Register
      register: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/register/${payload.token_uuid}`,
              method: 'POST',
              data: payload.data,
            });
            const { cs } = response.data;

            api.dispatch(login({ ...cs, refresh_tos: false })); // Add user data to redux store
            history.push('/dashboard');
            return { data: response.data };
          } catch (error) {
            if (error.response?.status === 409) {
              history.push('/validate/session');
              toast.success('Check your email for your code.');
            } else {
              toast.error('It looks like something went wrong.');
            }
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Send Forgot Password
      sendForgotPassword: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password-email`,
              method: 'POST',
              data: payload,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Validate MFA (Multi-Factor Authentication)
      validateMFA: build.mutation({
        queryFn: async (payload, api) => {
          try {
            if (!payload.email) {
              toast.error('No valid email found');
              return { data: 'No email' };
            }
            const response = await axios({
              url: `${baseURL}/validate/session`,
              method: 'POST',
              data: payload,
            });
            if (response.data.cs) {
              const { cs, refresh_tos } = response.data;
              ls.remove('email');
              api.dispatch(login({ ...cs, refresh_tos })); // Add user data to redux store
              history.push('/dashboard');
            } else {
              toast.warn('Something went wrong, Resend a new code');
            }
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Resend MFA Code
      resendMFACode: build.mutation({
        queryFn: async (payload) => {
          try {
            if (!payload.email) {
              toast.error('No valid email found');
              return { data: 'No email' };
            }
            const response = await axios({
              url: `${baseURL}/resend/mfa`,
              method: 'POST',
              data: { email: payload.email },
            });

            toast.success('New Code Sent');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Validate Password Reset Token
      validatePasswordResetToken: build.query({
        queryFn: async ({ token_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password/${token_uuid}`,
              method: 'GET',
              forced: true, // needs to get newly generated token from primary db
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Reset Password
      resetPassword: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password`,
              method: 'POST',
              data: payload,
            });
            ls.remove('user');
            toast.success('Password updated. Please log back in.');
            history.push('/login');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Get Password Complexity
      getPasswordComplexity: build.query({
        queryFn: async (_, { forced }) => {
          try {
            const response = await axios({
              url: `${baseURL}/pw-complexity`,
              method: 'GET',
              forced,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Reset Password Private (Logged In)
      resetPasswordPrivate: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password-private`,
              method: 'POST',
              data: payload,
            });
            api.dispatch(startLogout());
            toast.success('Password updated! Please log in again.');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Get Current TOS
      getTOS: build.query({
        queryFn: async (_, { forced }) => {
          try {
            const response = await axios({
              url: `${baseURL}/tos/current`,
              method: 'GET',
              forced,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Accept TOS
      acceptTOS: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/tos-agreement`,
              method: 'POST',
              data: payload,
            });
            api.dispatch(updateCurrentUser({ refresh_tos: false }));
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),

    }),
  });



export const {
  useLoginMutation,
  useLogoutMutation,

  useGetRegistrationDataQuery,
  useRegisterMutation,

  useSendForgotPasswordMutation,
  useValidateMFAMutation,
  useResendMFACodeMutation,

  useValidatePasswordResetTokenQuery,
  useResetPasswordMutation,

  useGetPasswordComplexityQuery,
  useResetPasswordPrivateMutation,

  useGetTOSQuery,
  useAcceptTOSMutation,

  useGetOrganizationBrandingQuery,
} = authApi;
